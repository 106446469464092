<template>
    <page title="销假" :hasBack="true" textAlign="left">
        <text-wrapper label="请假结束时间">
            <time-picker title="请假结束时间"  type="endTime" @selectConfirm="selectConfirm" />
        </text-wrapper>
        <common-block class="reason" title="销假理由">
            <van-field
                class="reason-input"
                v-model="reason"
                rows="4"
                autosize
                type="textarea"
                placeholder="请点击输入销假理由"
            />
        </common-block>
        <div class="btn-wrapper">
            <van-button class="confirm-btn" round type="primary" @click="confirmSubmit">确认提交</van-button>
        </div>
    </page>
</template>
<script>
    import Page from '@/components/page/Page';
    import CommonBlock from '@/components/commonBlock/CommonBlock.vue';
    import { reactive, ref } from 'vue';
    import TimePicker from '@/components/timePicker/TimePicker';
    import { Field, Button, Toast, Popup } from 'vant';
    import TextWrapper from '@/components/text/text';
    import commonRequest from '@/services';
    import dayjs from 'dayjs';
    import { DATE_TIME_FORMATE } from '@/constants';
    import {useRoute, useRouter} from 'vue-router';

    export default {
        name: 'cancelLeave',
        components: {
            TimePicker,
            [Page.name]: Page,
            [CommonBlock.name]: CommonBlock,
            [TimePicker.name]: TimePicker,
            [Field.name]: Field,
            [Button.name]: Button,
            [Popup.name]: Popup,
            [TextWrapper.name]: TextWrapper,
        },
        setup() {
            const router = useRouter();
            const route = useRoute();
            const formValues = reactive({
                endTime: '',
            });
            const selectConfirm = ({ type, value }) => {
                formValues[type] = value;
            };
            const reason = ref('');
            const confirmSubmit = () => {
                if (!formValues.endTime) {
                    Toast('请选择销假结束时间');
                    return;
                }
                if (!reason.value) {
                    Toast('请输入销假理由');
                    return;
                }
                commonRequest({ action: 12010, id: route.query.id, endTime: dayjs(formValues.endTime).format(DATE_TIME_FORMATE), reason: reason.value }).then(() => {
                    Toast.success('提交成功');
                    setTimeout(() => {
                        router.replace('/approveList');
                    }, 100);
                });
            };
            return {
                formValues,
                reason,
                confirmSubmit,
                selectConfirm
            };
        },
    };
</script>
<style lang="less" scoped>
    .reason-input {
        padding: 5px 10px 5px 0;
    }
    .btn-wrapper {
        margin-top: 59px;
        text-align: center;
        margin-bottom: 20px;
    }
</style>
